/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "uckeyeiosk-20220801130317-hostingbucket-main",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3hxd06861yxz1.cloudfront.net"
};


export default awsmobile;
